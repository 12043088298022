import React from "react";
import Product from "./Product"


class ProductList extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            productList:[       
                {goodsId:'982203315714',productName:'联通王卡',productVCode:'ltwk'}
            ]
        }
    
    
    };



    render() {
      let {onClick} = this.props;
      return this.state.productList.map((product, index) => {
                    return   <Product key={index}  onClick={onClick} goodsId={product.goodsId}  productName={product.productName} 
                    productVCode={product.productVCode}/>
                        
                });

    }
  }
  
  export default ProductList;