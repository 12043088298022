import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,NavLink,  Button , Card ,Modal,Spinner ,Breadcrumb,Container,Row,Col } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';
 

class FlexProductPanel extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        products:[]
      };

      console.log("加载产品列表");
      var that = this;
      axios.get( "hopeapi/product/pub/list" )
      .then(function (response) {
          console.log("request done");
          console.log(response);
          if(response.data.status){
  
            that.setState({
              products: response.data.data
            })
  
          }
          
      })


    };

     // 页面加在完成
  componentDidMount(){
   
   
  }


  
    
    render() {
      var that = this;
      console.log("render start");
      return (<Container fluid>
          <Row>
            
 
              {
                  that.state.products.map(li => 
                   
                    <Col xs={6} key={li.productVCode}>
                      
                      <NavLink href={"/#/product/"+li.productVCode+"/"+this.props.u}> 
                        
                        <img className="product-img" src={li.productCoverUrl} sm={6}></img>
                      {li.productTitle}
                      
                      </NavLink>
                      
                      
                      </Col>
                  )

              }

       
        </Row>
        </Container> 
        
        )  ;
    }
  }
  
  export default FlexProductPanel;