import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';


class KefuScreen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    var u=props.match.params.u;
    if(!u){
      //先尝试从本地缓存读取
      try {
        //尝试读取本地追踪标
        var local_u = localStorage.getItem("u");//获取存储的元素
        if(local_u){
          console.log("本地缓存有追踪标，重置默认推广员");
          u = local_u;
        }else{
          u="zuime";
        }
      }
      catch(err) {
        console.log("读取追踪标出现了异常");
        u="zuime";//使用默认标
      }
    
    }else{
      //写入本地缓存
      try {
        //写入本地永久缓存
        localStorage.setItem("u",u);//写入本地 追踪标
      }
      catch(err) {
        console.log("写入本地追踪标出现了异常");
      }
    }

    this.state = {
 
      u:u
    };
}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  console.log("可以提交数据了");

  axios.get("/phone/real/random")
  .then(function (response) {
    console.log(response);

  });
}


  render() {
 
   return  <div className="App">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href={"#/?u="+this.state.u}>
            免费选号
            </Breadcrumb.Item>
 
            <Breadcrumb.Item href={"#/big?u="+this.state.u}>
              特色卡
            </Breadcrumb.Item>
          </Breadcrumb>

            <Card body className="mt-3">客服服务</Card>
            <Card body className="mt-3">客服电话:13682586395</Card>
            <Card body className="mt-3">备用客服:13933393509</Card>
            <Card body className="mt-3">备用客服:15733892563</Card>
            <Card body className="mt-3">商务合作</Card>
            <Card body className="mt-3">商务电话:13682586395</Card>
        </Container>
       
    </div>
  }
}

export default KefuScreen;
